<template>
  <div class="zy-chart-ring">
    <canvas
      v-if="!isPie"
      ref="charts-text"
      class="zy-chart-ring-detail"
      :width="this.canvas_width"
      :height="this.canvas_height"
    ></canvas>
    <div
      v-if="!isPie"
      class="zy-chart-ring-detail"
    >
      <slot :params="autoPrams"></slot>
    </div>
    <div
      ref="charts"
      :style="{ width: `${canvas_width}px`, height: `${canvas_height}px` }"
    />
    <!-- <canvas
      ref="charts"
      :width="this.canvas_width"
      :height="this.canvas_height"
      :style="{ width: `${canvas_width}px`, height: `${canvas_height}px` }"
    ></canvas> -->
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      canvas_width: 0,
      canvas_height: 0,
      myChart: null,
      autoPrams: {},
    }
  },
  props: {
    //区块之间的间隔
    padAngle: {
      type: Number,
      default: 0,
    },
    //是否展示区块外面的文本
    showLine: {
      type: Boolean,
      default: false,
    },
    // 是否为饼图，如果不是饼图就是圆环
    isPie: {
      type: Boolean,
      default: false,
    },
    // 区块颜色顺序
    color: {
      type: Array,
      default: () => ["#66BBF9", "#FFB822", "#62FF85", "#007FF4"],
    },
    //区块数据, 对象格式{value: '', 'name':''}
    data: {
      type: Array,
      default: () => [],
    },
    // 饼图传Number,环型传数组。
    auto: {
      type: Array | Number,
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.updateChart()
        })
      },
    },
  },
  computed: {
    pieWidth() {
      if (this.auto) return this.auto
      if (this.isPie) {
        if (this.showLine) return 80
        return 90
      }
      if (this.showLine) return [65, 85]
      return [70, 90]
    },
    radius() {
      if (!Array.isArray(this.pieWidth)) return `${this.pieWidth}%`
      const list = []
      for (let i = 0; i < this.pieWidth.length; i++) {
        const width = this.pieWidth[i]
        list.push(`${width}%`)
      }
      return list
    },
    option() {
      return {
        tooltip: {
          show: true,
          trigger: "item",
          borderWidth: 1,
          backgroundColor: 'rgba(30,80,135,0.95)',
          borderColor: '#33D8FA',
          confine: true,
          textStyle: {
            fontFamily: "SourceHanSansCN",
            fontWeight: 400,
            color: "#ffffff",
            fontSize: 14,
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: this.radius,
            avoidLabelOverlap: true,
            padAngle: this.padAngle,
            label: {
              show: this.showLine, //辅助文本
            },
            labelLine: {
              show: this.showLine, // 辅助线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            //动画时间
            animationDuration: 2500,
            data: this.data,
          },
        ],
        color: this.color,
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.canvas_width = this.$el.clientWidth
      this.canvas_height = this.$el.clientHeight
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        if (!this.myChart) {
          var chartDom = this.$refs.charts
          var myChart = echarts.init(chartDom, null, {
            devicePixelRatio: window.devicePixelRatio || 1,
          })
          myChart.dispatchAction({
            type: "dataZoom",
            start: 20,
            end: 30,
          })
          // 监听鼠标移上事件
          const _this = this
          myChart.on("mouseover", function (params) {
            console.log("mouseover", params)
            _this.autoPrams = params
          })

          myChart.on("mouseout", function (params) {
            console.log("mouseout")
            _this.autoPrams = {}
          })
          myChart.setOption(this.option)
          this.myChart = myChart
        }
        this.drawerText()
      })
    },
    updateChart() {
      this.myChart.setOption(this.option)
    },
    drawerText() {
      if (this.isPie) return
      const canvas = this.$refs["charts-text"]
      const ctx = canvas.getContext("2d")
      let rate = this.pieWidth[0] / 100
      let radius = canvas.width * rate
      if (canvas.height < canvas.width) {
        radius = canvas.height * rate
      }

      var circle = {
        x: canvas.width / 2,
        y: canvas.height / 2,
        radius: radius / 2 - 4,
      }
      if (circle.radius < 0) circle.radius = 0
      ctx.beginPath()
      ctx.arc(circle.x, circle.y, circle.radius, 0, 2 * Math.PI)

      var dashArray = [5, 5] // 数组元素决定线段与间隔，数组元素相加应小于等于2πr/gap
      var gap = 10
      ctx.setLineDash(dashArray)
      ctx.lineDashOffset = gap

      ctx.strokeStyle = "rgba(255, 255, 255, 0.2)"
      ctx.lineWidth = 2
      ctx.stroke()
    },
  },
}
</script>

<style lang="less" scoped>
.zy-chart-ring {
  position: relative;
  width: 100%;
  height: 100%;
  &-detail {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
